import clsx from 'clsx';
import Markdown from 'components/common/Markdown';

import ParagraphHeader from 'components/ParagraphHeader';

import { MapAndTextBoxProps } from 'interfaces/cms/content';

function MapAndTextBox({ header, rows }: MapAndTextBoxProps) {
  return (
    <>
      <div className="px-[0.9375rem] py-[1.875rem]">
        {header && (
          <>
            <ParagraphHeader {...header} />
            <div className="mb-[1.875rem]" />
          </>
        )}
      </div>
      {rows?.map((element: any) => (
        <div
          className={clsx(
            'max-w-[69.375rem] mx-auto my-0 px-[1.25rem] pb-[1.875rem] grid md:grid-cols-10 grid-cols-1 gap-6'
          )}
          key={element.id}
        >
          <div
            className={clsx(
              '[&>*]:w-full overflow-hidden h-80',
              element?.map_position === 'left' ? 'order-1' : 'order-2',
              element?.layout === 'L-50:50' ? 'col-span-5' : '',
              element?.layout === 'L-80:20' ? 'md:col-span-7' : '',
              element?.layout === 'L-100' ? 'col-span-10' : ''
            )}
            dangerouslySetInnerHTML={{ __html: element.embed_map_code }}
          ></div>

          <Markdown
            className={clsx(
              '[&>*]:list-disc',
              element?.map_position === 'left' ? 'order-2' : 'order-1',
              element?.layout === 'L-50:50' ? 'col-span-5' : '',
              element?.layout === 'L-80:20' ? 'md:col-span-3' : '',
              element?.layout === 'L-100' ? 'col-span-10' : ''
            )}
            md={element.textbox}
            textAlign="left"
          />
        </div>
      ))}
    </>
  );
}

export default MapAndTextBox;
